import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';

const userData = localStorage.getItem('user');
if (userData) {
  store.commit('setUser', JSON.parse(userData));
}

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')
