<template>
  <div class="popup-menu">
    <div class="menu">
      <i @click="$emit('closePopup')" class="fa-solid fa-close"></i>
    </div>
    <div class="content">
      <div class="button-container" v-if="currentStep == 'settings'">
        <h1>Inställningar</h1>
        <p style="color: green;">{{ savingUserDetailsInfo }}</p>
        <label for="userWordsPerDay">Nya ord per dag</label>
        <input type="number" name="userWordsPerDay" v-model="newUser.wordsPerDay">
        <ActionButton 
          style="margin-top:10px;" 
          :followText="true" 
          @click="setUser"
          :disabled="savingUserDetails"
        >Spara</ActionButton>
      </div>
      <div class="button-container" v-if="currentStep == 'report'">
        <div v-if="reportProcessStep == 1">
          <h1>Rapportera ord</h1>
          <textarea
          v-model="reportDescription"
          placeholder="Ge en förklaring..."
        ></textarea>
        <ActionButton 
          :followText="true" 
          fontSize="12pt" 
          @click="reportWord"
          :disabled="reportDescription == ''"
          >Rapportera</ActionButton
        >
        </div>
        <div v-if="reportProcessStep == 2">
          <h1>Rapportera ord</h1>
          <p>Skickar rapport...</p>
        </div>
        <div v-if="reportProcessStep == 3">
          <h1>Rapporterat!</h1>
          <p>Tack för din rapport! Vi kommer ta en titt på rapporten så snart som möjligt.</p>
        </div>
      </div>
      <div class="button-container" v-if="currentStep == 'main'">
        <h1>Vad vill du göra?</h1>
        <ActionButton
          :followText="true"
          fontSize="12pt"
          @click="currentStep = 'remove'"
          >Ta bort ordet</ActionButton
        >
        <ActionButton
          :followText="true"
          fontSize="12pt"
          @click="currentStep = 'improve'"
          >Förbättra ordet</ActionButton
        >
      </div>
      <div class="button-container" v-if="currentStep == 'remove'">
        <h1>Varför vill du ta bort?</h1>
        <!-- <ActionButton :followText="true" fontSize="12pt" @click="$emit('obviousWord'); $emit('closePopup')">Väldigt enkelt ord</ActionButton> -->
        <ActionButton
          :followText="true"
          fontSize="12pt"
          @click="
            $emit('removeWord');
            $emit('closePopup');
          "
          >Olämpligt</ActionButton
        >
        <ActionButton
          :followText="true"
          fontSize="12pt"
          @click="
            $emit('notRelevantWord');
            $emit('closePopup');
          "
          >Överflödigt eller irrelevant</ActionButton
        >
        <!-- <ActionButton :followText="true" fontSize="12pt" @click="currentStep = 'findSimilarWord'">Ordet borde ha ett annat format</ActionButton> -->
      </div>
      <div class="button-container" v-if="currentStep == 'improve'">
        <h1>Hur vill du förbättra?</h1>
        <ActionButton
          :followText="true"
          fontSize="12pt"
          @click="currentStep = 'renameWord'"
          >Slå ihop med andra ord</ActionButton
        >
        <ActionButton
          :followText="true"
          fontSize="12pt"
          @click="$emit('incompleteWord')"
          >Markera som ofullständigt</ActionButton
        >
        <ActionButton
          :followText="true"
          fontSize="12pt"
          @click="
            loadCategories();
            currentStep = 'categorize';
          "
          >Kategorisera</ActionButton
        >
      </div>
      <div class="button-container" v-if="currentStep == 'renameWord'">
        <h1>Hitta liknande ord</h1>
        <!-- <i>Döper om {{ word }}</i> -->
        <input
          type="text"
          placeholder="Sök..."
          v-model="searchInput"
          @input="searchForWords"
        />
        <div class="search-results" v-if="searchResults.length > 0">
          <span
            v-for="result in searchResults"
            class="clickable-word"
            :key="result.id"
            :class="{
              'highlighted-word':
                addedWords.find((w) => w.id == result.id) != null,
            }"
            @click="toggleWordAddition(result)"
            >{{ result.word }}</span
          >
        </div>
        <p v-if="searchResults.length == 0 && searchInput.length < 3">
          Sök på något! Minst tre tecken
        </p>
        <p
          v-if="
            !isSearching && searchInput.length >= 3 && searchResults.length == 0
          "
        >
          Inga resultat
        </p>
        <p v-if="isSearching">Söker...</p>
        <ActionButton
          :followText="true"
          fontSize="12pt"
          :disabled="addedWords.length == 0"
          @click="currentStep = 'renameWord2'"
          >Fortsätt</ActionButton
        >
      </div>
      <div class="button-container" v-if="currentStep == 'renameWord2'">
        <h1>Välj rätt ord</h1>
        <p>Välj det ord som bäst representerar gruppen av ord</p>
        <div class="search-results">
          <span
            v-for="result in [word, ...addedWords]"
            class="clickable-word"
            :key="result.id"
            :class="{ 'highlighted-word': representativeWord.id == result.id }"
            @click="representativeWord = result"
            >{{ result.word }}</span
          >
        </div>
        <ActionButton
          :followText="true"
          fontSize="12pt"
          :disabled="Object.keys(representativeWord).length == 0"
          @click="
            $emit('consolidateWords', {
              words: addedWords,
              representativeWord: representativeWord,
            });
            $emit('closePopup');
          "
          >Konsolidera</ActionButton
        >
      </div>
      <div class="button-container" v-if="currentStep == 'categorize'">
        <h1>Välj kategori</h1>
        <input
          type="text"
          placeholder="Skapa ny kategori"
          v-model="newCategoryInput"
        />
        <div class="search-results" v-if="categories.length > 0">
          <span
            v-if="newCategoryInput != ''"
            class="clickable-word"
            :class="{
              'highlighted-word': chosenCategory.category == newCategoryInput,
            }"
            @click="chosenCategory = { category: newCategoryInput }"
            >{{ newCategoryInput }}</span
          >
          <span
            v-for="category in categories"
            class="clickable-word"
            :key="category.id"
            :class="{
              'highlighted-word': chosenCategory.category == category.category,
            }"
            @click="chosenCategory = category"
            >{{ category.category }}</span
          >
        </div>
        <ActionButton
          :followText="true"
          fontSize="12pt"
          :disabled="chosenCategory.category == null"
          @click="$emit('setCategory', chosenCategory.category)"
          >Spara</ActionButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from "./ActionButton.vue";

export default {
  data() {
    return {
      steps: [
        "main",
        "remove",
        "improve",
        "renameWord",
        "renameWord2",
        "categorize",
        "report",
        "settings"
      ],
      currentStep: "main",
      searchInput: "",
      searchResults: [],
      isSearching: false,
      addedWords: [],
      representativeWord: {},
      categories: [],
      chosenCategory: {},
      newCategoryInput: "",
      reportDescription: "",
      reportProcessStep: 1,
      newUser: {
        wordsPerDay: 0
      },
      savingUserDetails: false,
      savingUserDetailsInfo: ''
    };
  },
  props: {
    word: {
      type: Object,
      default: null,
    },
    initalStep: {
      type: String,
      default: "main",
    },
    user: {
      type: Object,
      default: Object
    }
  },
  created() {
    this.newUser = this.user;
    this.currentStep = this.initalStep;
  },
  methods: {
    setUser() {
      this.savingUserDetails = true;
      this.savingUserDetailsInfo = '';

      fetch('/api/user/edit', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + this.$store.state.user.token,
        },
        body: JSON.stringify({
          wordsPerDay: this.newUser.wordsPerDay
        })
      })
      .then((res) => res.json())
      .then(() => {
        this.savingUserDetailsInfo = 'Inställningarna sparade!';
        this.savingUserDetails = false;
      })
    },
    getUser() {
      fetch('/api/user', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + this.$store.state.user.token,
        }
      })
      .then((res) => res.json())
      .then((user) => {
        console.log(user)
      })
    },
    reportWord() {
      if (this.reportDescription == '') {
        return;
      }

      this.reportProcessStep = 2;
      fetch("/api/report", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Charset: "utf-8",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
        body: JSON.stringify({
          report: this.reportDescription,
          wordId: this.word.id,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.reportProcessStep = 3;
				});
    },
    loadCategories() {
      fetch("/api/categories")
        .then((res) => res.json())
        .then((categories) => {
          this.categories = categories;
        });
    },
    searchForWords() {
      if (this.searchInput.length < 3) {
        this.searchResults = [];
        return;
      }
      if (this.isSearching) return;
      // TODO: Make sure to search for what is actually in the input.
      // If a search is in progress and searchInput changes, it won't trigger
      // a new search automatically if the response comes after

      this.isSearching = true;
      fetch("/api/word/search/" + this.searchInput, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
      })
        .then((res) => res.json())
        .then(({ words }) => {
          this.searchResults = words;
          this.isSearching = false;
        });
    },
    toggleWordAddition(word) {
      if (this.addedWords.find((w) => w.id == word.id) != null) {
        this.addedWords = this.addedWords.filter((w) => w.id != word.id);
      } else {
        this.addedWords.push(word);
      }
    },
  },
  components: {
    ActionButton,
  },
  emits: [
    "closePopup",
    "obviousWord",
    "removeWord",
    "notRelevantWord",
    "consolidateWords",
    "incompleteWord",
    "setCategory",
  ],
};
</script>

<style scoped>
.popup-menu {
  position: fixed;
  width: 100%;
  background-color: white;
  /* border-top: 1px solid white; */
  color: black;
  bottom: 0;
  z-index: 100;
  border-radius: 25px 25px 0 0;
  padding: 20px;
}

h1 {
  font-size: 14pt;
}

.menu {
  text-align: right;
}

.search-results {
  max-height: 300px;
  margin: 10px 0;
  overflow-y: scroll;
}

label {
  display: block;
  text-align: left;
  padding: 10px;
}

textarea, input {
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #ccc;
}

.clickable-word {
  display: block;
  padding: 5px;
  background-color: #ccc;
}

.clickable-word.highlighted-word {
  background-color: rgb(245, 91, 91);
  color: rgb(103, 0, 0);
}

.clickable-word + .clickable-word {
  margin-top: 5px;
}
</style>
