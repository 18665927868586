<template>
  <div id="app">
    <nav>
      <router-link to="/" class="navbutton">
        <i class="fa-solid fa-angle-left"></i>
      </router-link>
      <span @click="popupInitalStep = 'settings'; popupMenuActive = true" class="navbutton">
        <i class="fa-solid fa-gear"></i>
      </span>
    </nav>
    <ProgressBar :current="progress.current" :max="progress.max" />
    <!-- <HistoryBar :stats="history" /> -->
    <div id="content">
      <p v-if="error != ''" class="error">Något gick fel! Felmeddelande: {{ error }}</p>
      <div v-if="doneForToday == true">
        <h1>Klar för i dag!</h1>
        <p>Du har repeterat klart för i dag. Välkommen tillbaka i morgon!</p>
      </div>
      <PopupMenu
        v-if="popupMenuActive"
        :word="wordObject"
        :user="$store.state.user"
        :initalStep="popupInitalStep"
        @closePopup="popupMenuActive = false"
        @removeWord="updateWord({ isAppropriate: false })"
        @notRelevantWord="updateWord({ isRelevant: false })"
        @obviousWord="updateWord({ isObvious: true })"
        @consolidateWords="consolidateWords"
        @incompleteWord="updateWord({ iscomplete: false })"
        @setCategory="setCategory"
      />
      <div v-if="error == '' && doneForToday == false">
        <h1>{{ wordObject.word }}</h1>
        <ul v-if="showDescriptions">
          <li v-if="category">Kategori: {{ category.category }}</li>
          <li v-for="description of descriptions" :key="description.id">
            {{ description.explanation }}
          </li>
          <p v-if="descriptions.length == 0">Ordet saknar förklaring</p>
        </ul>
        <div v-if="!showDescriptions && this.wordObject.word !== '...'">
          <ActionButton
            @click="explainWord"
            description="Förklara"
            :disabled="showDescriptions"
            ><i class="fa-solid fa-question"></i
          ></ActionButton>
        </div>
        <div class="buttonContainer">
          <ScaleBox 
            v-if="$store.state.user.isAdmin"
            :selectedValue="wordObject.commonLevel"
            @setCommonLevel="setCommonLevel" />
          <div class="buttons">
            <ActionButton
              @click="setWord(true, false)"
              description="Kan"
              backgroundColor="green"
              borderColor="0 5px 0 0 rgb(2, 97, 2)"
              ><i class="fa-solid fa-thumbs-up"></i
            ></ActionButton>
            <ActionButton
              v-if="$store.state.user.isAdmin"
              @click="openPopup('main')"
              description="Åtgärda"
              backgroundColor="blue"
              borderColor="0 5px 0 0 #000080"
              ><i class="fa-solid fa-ellipsis"></i
            ></ActionButton>
            <ActionButton
              v-if="!$store.state.user.isAdmin"
              @click="openPopup('report')"
              description="Rapportera"
              backgroundColor="blue"
              borderColor="0 5px 0 0 #000080"
              ><i class="fa-solid fa-bug"></i
            ></ActionButton>
            <ActionButton @click="setWord(true, true)" description="Självklar"
              backgroundColor="blue"
              borderColor="0 5px 0 0 #000080"
              ><i class="fa-solid fa-trash"></i
            ></ActionButton>
            <ActionButton
              @click="setWord(false, false)"
              description="Kan inte"
              backgroundColor="#ff0000"
              borderColor="0 5px 0 0 #800000"
              ><i class="fa-solid fa-thumbs-down"></i
            ></ActionButton>
            <!-- <ActionButton description="Nytt ord"><i class="fa-solid fa-rotate-right"></i></ActionButton> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from "@/components/ActionButton.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import PopupMenu from "@/components/PopupMenu.vue";
import ScaleBox from "@/components/ScaleBox.vue";
// import HistoryBar from '@/components/HistoryBar.vue'

export default {
  data() {
    return {
      wordObject: { word: "..." },
      wordClass: "",
      showDescriptions: false,
      descriptions: [],
      category: null,
      progress: { current: 0, max: 1000 },
      error: "",
      popupMenuActive: false,
      history: [],
      doneForToday: false,
      popupInitalStep: 'main'
    };
  },
  components: {
    ActionButton,
    ProgressBar,
    PopupMenu,
    ScaleBox,
    // HistoryBar
  },
  name: "App",
  created() {
    this.getNewWord();
  },
  methods: {
    openPopup(reason) {
      this.popupInitalStep = reason;
      this.popupMenuActive = true;
    },
    setCommonLevel(commonLevel) {
      fetch('/api/word', {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
        body: JSON.stringify({
          word: this.wordObject.word,
          commonLevel: commonLevel,
        }),
      })
      .then((res) => res.json())
      .then(() => {
        this.wordObject.commonLevel = commonLevel;
      })
    },
    setCategory(category) {
      fetch("/api/set_category", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
        body: JSON.stringify({
          word: this.wordObject.word,
          category: category,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.getNewWord();
        });
    },
    consolidateWords(data) {
      const { words, representativeWord } = data;
      fetch("/api/word/replace", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
        body: JSON.stringify({
          word: representativeWord,
          wordsToReplace: [
            ...words.filter((w) => w.id != representativeWord.id),
            this.wordObject,
          ],
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.popupMenuActive = false;
          this.getNewWord();
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
    resetData() {
      this.wordObject = { word: "..." };
      this.wordClass = "";
      this.showDescriptions = false;
      this.descriptions = [];
      this.category = null;
      this.isExplained = false;
      this.popupMenuActive = false;
      this.history = [];
    },
    getNewWord() {
      this.resetData();

      fetch("/api/v2/word", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const { word, explanations, history, category } = data;

          if (!word) {
            this.doneForToday = true;
            return;
          }

          
          
          this.wordObject = word;
          this.history = history;
          this.descriptions = explanations;
          if (category) {
            this.category = category;
          }
        })
        .catch((err) => {
          this.error = err.message;
        });

      fetch("/api/word_stats", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
      })
        .then((res) => res.json())
        .then(({ current, max }) => {
          this.progress.current = current;
          this.progress.max = max;
        });
    },
    explainWord() {
      this.showDescriptions = true;
    },
    setWord(knowsWord, isObvious) {
      fetch("/api/v2/word", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
        body: JSON.stringify({
          word: this.wordObject.word,
          knowsWord: knowsWord,
          isObvious: isObvious,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.getNewWord();
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
    updateWord(wordSettings) {
      fetch("/api/word", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
        body: JSON.stringify({ word: this.wordObject.word, ...wordSettings }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message) {
            this.error = data.message;
          } else {
            this.getNewWord();
          }
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
  },
};
</script>

<style scoped>
#app {
  width: 100vw;
  height: 100vh;
}

nav {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

nav .navbutton {
  display: block;
  padding: 10px;
  color: white;
  text-decoration: none;
  font-size: 25px;
}

p {
  padding: 0 20px;
}

p.error {
  text-align: center;
  color: red;
}

#content {
  text-align: center;
  margin-top: 50px;
  width: 100vw;
}

h1 {
  font-family: "Times New Roman", Times, serif;
}

ul {
  width: 100vw;
  list-style-type: none;
  text-align: center;
  padding: 20px;
}

li {
  font-size: 1.5em;
}

li + li {
  margin-top: 10px;
}

.buttonContainer {
  position: fixed;
  bottom: 0;
  padding: 25px 0;
  width: 100vw;
  margin-top: 50px;
  background-color: #121212;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* gap: 15px; */
}
</style>
