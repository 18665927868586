import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import GameArea from './views/GameArea.vue';
import Login from './views/LoginPage';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/words',
    name: 'Words',
    component: GameArea,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
  // Define other routes here
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;