<template>
    <div id="container">
        <h1>Logga in</h1>
        <p v-if="error !== ''" class="error">{{ error }}</p>
        <form @submit.prevent="login">
            <input type="text" placeholder="Användarnamn" v-model="username">
            <input type="password" placeholder="Lösenord" v-model="password">
            <ActionButton :followText="true" fontSize="14pt" style="margin-top:10px;" description="" @click="login">Logga in</ActionButton>
        </form>
    </div>
</template>

<script>
import ActionButton from '@/components/ActionButton.vue';

export default {
    components: {
        ActionButton
    },
    data() {
        return {
            username: '',
            password: '',
            error: ''
        }
    },
    methods: {
        login() {
            if (!this.username || !this.password) {
                this.error = "Du måste ange både användarnamn och lösenord";
                return;
            }

            fetch('/api/user/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: this.username,
                    password: this.password
                })
            })
            .then((res) => res.json())
            .then((user) => {
                if (user.message) {
                    this.error = user.message;
                } else {
                    this.$store.commit('setUser', user);
                    this.$router.push('/');
                }
            })
            .catch((err) => {
                this.error = err.message;
            })
        }
    }
}
</script>

<style scoped>
#container {
    padding: 10px;
}

form {
    text-align: center;
}

input {
    width: 100%;
    display: block;
    padding: 10px;
}

input + input {
    margin-top: 10px;
}

p.error {
    color: red;
}
</style>