<template>
  <div id="container">
    <h1>Startsidan</h1>
    <div v-if="!isLoggedIn">
      <p>Du är inte inloggad!</p>
      <router-link to="/login">Klicka här</router-link> för att logga in
    </div>
    <div v-if="isLoggedIn">
      <p>
        Välkommen, {{ userData.username }}!
        <button @click="logout()">Logga ut</button>
      </p>
			<ActionButton @click="$router.push('/words')" :followText="true" description="">Svenska glosboken</ActionButton>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/ActionButton.vue';

export default {
	components: {
		ActionButton
	},
  methods: {
    logout() {
      this.$store.commit("clearUser");
    },
  },
  computed: {
    userData() {
      return this.$store.getters.getUser;
    },
    isLoggedIn() {
      return !!this.userData;
    },
    greetingsMessage() {
      return this.isLoggedIn
        ? `Välkommen tillbaka, ${this.userData.username}!`
        : "Hej! Du är inte inloggad.";
    },
  },
};
</script>

<style scoped>
#container {
  text-align: center;
	padding: 10px;
}

a {
  color: green;
}
</style>
