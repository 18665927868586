import { createStore } from "vuex";

export default createStore({
    state: {
        user: null
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        clearUser(state) {
            state.user = null;
            localStorage.removeItem('user');
        }
    },
    actions: {
        logout({ commit }) {
            commit('clearUser')
        }
    },
    modules: {

    },
    getters: {
        getUser(state) {
            return state.user;
        }
    }
});