<template>
  <div class="button">
    <button :disabled="disabled" :style="{
      backgroundColor: this.backgroundColor,
      boxShadow: this.borderColor,
      fontSize: this.fontSize
    }"
    :class="{
      squareButton: !followText,
      fullWidth: followText 
    }">
      <slot></slot>
    </button>
    <span class="description">{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    description: {
      type: String,
      default: '...'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: 'green'
    },
    borderColor: {
      type: String,
      default: '0 5px 0 0 rgb(2, 97, 2)'
    },
    followText: {
      type: Boolean,
      default: false
    },
    fontSize: {
      type: String,
      default: '16pt'
    }
  }
}
</script>

<style scoped>
.button {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 1.5em;
  border-radius: 15px;
  border: none;
  /* box-shadow: 0 5px 0 0 rgb(2, 97, 2); */
  color: white;
}

button.squareButton {
  width: 50px;
}

button.fullWidth {
  /* padding: 20px; */
  width: 100%;
  /* display: block; */
}

button:disabled {
  background-color: grey !important;
  box-shadow: 0 5px 0 0 rgb(70, 70, 70) !important;
}

.description {
  font-size: 1em;
  color: white;
}
</style>
