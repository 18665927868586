<template>
  <div id="content">
		<ActionButton 
			v-for="i in [1,2,3,4,5]" 
			:key="i" 
			description=""
			:backgroundColor="(selectedValue == i) ? 'green' : 'grey'"
			:borderColor="(selectedValue == i) ? '0 5px 0 0 rgb(2, 97, 2)' : '0 5px 0 0 #45474B'"
			@click="$emit('setCommonLevel', i)"
			>{{ i }}</ActionButton>
  </div>
</template>

<script>
import ActionButton from "./ActionButton.vue";

export default {
	props: {
		selectedValue: {
			type: Number
		},
	},
  components: {
    ActionButton,
  },
};
</script>

<style scoped>
#content {
  width: 100%;
  padding: 10px;
	display: flex;
	flex-direction: row;
}
</style>
