<template>
    <div class="container">
        <p>{{ current }} av {{ max }}</p>
        <div class="progressbar">
            <div class="progress" :style="{ width: (current / max) * 100 + '%' }"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        current: Number,
        max: Number
    },
}
</script>

<style scoped>
.container {
    width: 100%;
    text-align: center;
    padding: 20px;
}

p {
    margin: 0;
    margin-bottom: 10px;
    min-height: 10pt;
    font-size: 10pt;
}

.progress {
    background-color: green;
    height: 8px;
    border-radius: 25px;
}

.progressbar {
    width: 100%;
    border: 1px solid green;
    padding: 2px;
    border-radius: 25px;
}
</style>